import { z } from 'zod';
import * as routes from 'lib/routes';
import { apiSlice } from 'api/base_slice';
import { POPULATION_ATTRIBUTES_TAG } from 'api/tag_types';
import {
  deserializeJSONApiResponse,
  headersAndCredentials,
  sortAlphabeticalByName,
} from 'api/utils';

// TODO: Update to use RSwag instead of explicitly declaring this
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const populationAttributesSchema = z.object({
  data: z.array(
    z.object({
      id: z.string(),
      attributes: z.object({
        name: z.string(),
        required: z.boolean(),
        type: z.string(),
        slug: z.string(),
      }),
      relationships: z.object({
        options: z.object({
          data: z.array(
            z.object({
              id: z.string(),
            }),
          ),
        }),
      }),
      meta: z.object({
        canDelete: z.boolean(),
        canUpdate: z.boolean(),
      }),
    }),
  ),
  included: z
    .array(
      z.object({
        id: z.string(),
        type: z.literal('participantPopulationAttributeSelectOption'),
        attributes: z.object({
          description: z.string().nullable(),
          isExclusive: z.boolean(),
          participantPopulationAttributeId: z.number(),
          position: z.number(),
          name: z.string(),
        }),
      }),
    )
    .optional(),
});

export type PopulationAttributesRawResponse = z.infer<typeof populationAttributesSchema>;
export type PopulationAttributesAPIResponse =
  ReturnType<typeof sortAlphabeticalByName<ReturnType<typeof deserializeJSONApiResponse>>>;

type DeletePopulationAttributeParams = {
  participantPopulationId: string;
  participantPopulationAttributeId: string;
};

type UpdatePopulationAttributeParams = {
  participantPopulationId: string;
  participantPopulationAttributeId: string;
  patch: string;
};

type CreatePopulationAttributeParams = {
  participantPopulationId: string;
  name: string;
  type: string;
};

export const populationAttributesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPopulationAttributes: builder.query<PopulationAttributesAPIResponse, string>({
      query: (participantPopulationId: string) =>
        routes.api_participant_population_attributes_path(participantPopulationId),
      providesTags: [POPULATION_ATTRIBUTES_TAG],
      transformResponse: (response: PopulationAttributesRawResponse): PopulationAttributesAPIResponse =>
        sortAlphabeticalByName(deserializeJSONApiResponse(response)),
    }),
    deletePopulationAttribute: builder.mutation<void, DeletePopulationAttributeParams>({
      query: ({ participantPopulationId, participantPopulationAttributeId }) => ({
        url: routes.api_participant_population_attribute_path(participantPopulationId, participantPopulationAttributeId),
        method: 'DELETE',
        ...headersAndCredentials(),
      }),
      invalidatesTags: [POPULATION_ATTRIBUTES_TAG],
    }),
    updatePopulationAttribute: builder.mutation<PopulationAttributesRawResponse, UpdatePopulationAttributeParams>({
      query: ({ participantPopulationId, participantPopulationAttributeId, ...patch }) => ({
        url: routes.api_participant_population_attribute_path(participantPopulationId, participantPopulationAttributeId),
        method: 'PATCH',
        body: {
          data: {
            attributes: patch,
          },
        },
        ...headersAndCredentials(),
      }),
      invalidatesTags: [POPULATION_ATTRIBUTES_TAG],
    }),
    createPopulationAttribute: builder.mutation<PopulationAttributesRawResponse, CreatePopulationAttributeParams>({
      query: ({ participantPopulationId, name, type }) => {
        const params = {
          data: {
            attributes: {
              name,
              type,
            },
          },
        };

        return {
          url: routes.api_participant_population_attributes_path(participantPopulationId),
          method: 'POST',
          body: params,
          ...headersAndCredentials(),
        };
      },
      invalidatesTags: [POPULATION_ATTRIBUTES_TAG],
    }),
  }),
  overrideExisting: 'throw',
});

export const {
  useCreatePopulationAttributeMutation,
  useDeletePopulationAttributeMutation,
  useGetPopulationAttributesQuery,
  useUpdatePopulationAttributeMutation,
} = populationAttributesApi;
